import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from '@/hooks/useSession';

import api from '@/services/apis';

const withOneTimeToken = (WrappedComponent) => {
  const WithOneTimeTokenComponent = (props) => {
    const router = useRouter();
    const session = useSession();
    const [isUsed, setIsUsed] = useState(false);

    const fetchOneTimeToken = useCallback(async () => {
      const oneTimeToken = router.query?.token;
      if (!oneTimeToken || !session || !router?.isReady) {
        return;
      }

      setIsUsed(true);

      const response = await api.fetchAccessTokenByOneTimeToken({
        data: {
          token: oneTimeToken,
        },
      });

      if (response.token) {
        session.signIn({
          accessToken: response.token,
        });
      }

      // 清除網址中的 querystring 防止無限迴圈
      const { pathname, query } = router;
      delete query.token;

      // 重新導向，處理 Next.js canceled error
      setTimeout(() => {
        if (response.error) {
          router.replace('/login');
          return;
        }
        router.replace({
          pathname,
          query,
        });
      }, 600);
    }, [router, session, setIsUsed]);

    useEffect(() => {
      if (isUsed) {
        return;
      }
      fetchOneTimeToken();
    }, [fetchOneTimeToken, isUsed]);

    return <WrappedComponent {...props} />;
  };

  return WithOneTimeTokenComponent;
};

export default withOneTimeToken;
